import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div``;

const Big = styled.div`
  position: relative;
  width: 100%;
  height: 220px;

  @media (min-width: 767px) {
    height: 555px;
  }

  @media (max-width: 680px) {
    button {
      &.right {
        display: none;
      }
      &.left {
        display: none
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px;
  }
  i {
    background: url(/images/zoom_foto.png) no-repeat center;
    height: 41px;
    width: 41px;
    position: absolute;
    bottom: 3px;
    right: 3px;
  }

  button {
    min-width: 45px;
    background: #ebebeb;
    border: 0;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 45px;
    border-radius: 3px;
    top: 50%;
    margin-top: -30px;
    &.right {
      background-image: url(/images/arrow-right-bold.png);
      right: 21px;
    }
    &.left {
      background-image: url(/images/arrow-left-bold.png);
      left: 21px;
    }
    &:hover {
      background-color: #E59134;
      &.right {
        background-image: url(/images/arrow-right.png);
        background-size: 22px 16px;
      }
      &.left {
        background-image: url(/images/arrow-right.png);
        background-size: 22px 16px;
        transform: rotate(180deg);
      }
    }
`;

const Options = styled.div`
  display: flex;
  align-content: space-between;
  margin-top: 25px;
  width: 100%;
  height: 113px;
  .holder {
    margin: 0 8px;
    width: 100%;
    display: flex;
    overflow: hidden;
    div {
      transition: all 0.3s;
      display: flex;
      width: 100%;
    }
    span {
      margin: 0 8px;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      height: 113px;
      min-width: calc(25% - 16px);
      border-radius: 3px;

      @media (max-width: 1200px) {
        min-width: calc(33% - 16px);
      }
      @media (max-width: 767px) {
        min-width: calc(100% - 16px);
      }
      .overlay {
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: -8px;
        right: 0;
        bottom: 0;
        transition: all 0.3s;
        opacity: 0;
        min-width: 100%;
      }
      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }
  }
  button {
    min-width: 53px;
    background: #ebebeb;
    border-radius: 3px;
    border: 0;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 113px;
    @media (min-width: 1200px) {
      min-width: 83px;
    }
    &.right {
      background-image: url(/images/arrow-right-bold.png);
    }
    &.left {
      background-image: url(/images/arrow-left-bold.png);
    }
    &:hover {
      background-color: #e59134;
      &.right {
        background-image: url(/images/arrow-right.png);
        background-size: 22px 16px;
      }
      &.left {
        background-image: url(/images/arrow-right.png);
        background-size: 22px 16px;
        transform: rotate(180deg);
      }
    }
  }
`;

export default function GalleryModal({ gallery }) {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [slide, setSlide] = useState(0);
  const [slideTotal, setSlideTotal] = useState(0);
  const [pace, setPace] = useState(0);

  const resize = () => {
    if (document.body.clientWidth < 767) {
      setPace(100);
      setSlideTotal(gallery.length - 1);
    } else if (
      document.body.clientWidth > 767 &&
      document.body.clientWidth < 1200
    ) {
      setPace(33);
      setSlideTotal(gallery.length - 3);
    } else {
      setPace(25);
      setSlideTotal(gallery.length - 4);
    }
  };

  useEffect(() => {
    resize();

    window.addEventListener("resize", () => resize);

    return () => {
      window.removeEventListener("resize", () => resize);
    };
  }, []);

  function slideLeft() {
    if (slide > 0) {
      setSlide(slide - 1);
    }
  }

  function slideRight() {
    if (slide < slideTotal) {
      setSlide(slide + 1);
    }
  }

  function slideLeftBig() {
    if (activeImageIndex > 0) {
      setActiveImageIndex(activeImageIndex - 1);
    } else {
      setActiveImageIndex(gallery.length - 1);
    }
  }

  function slideRightBig() {
    if (activeImageIndex < gallery.length - 1) {
      setActiveImageIndex(activeImageIndex + 1);
    } else {
      setActiveImageIndex(0);
    }
  }

  const getThumbnail = (url) => {
    let imageArray = url.split("/");
    let imageName = imageArray[imageArray.length - 1];
    let imageThumb =
      "https://apimeuambiente.guararapes.com.br/assets/" + imageName;

    return imageThumb;
  };

  return (
    <Container>
      <Big>
        <span
          style={{
            backgroundImage: `url(${
              gallery[activeImageIndex] &&
              getThumbnail(gallery[activeImageIndex].url)
            })`,
          }}
        ></span>
        <button className="left" onClick={slideLeftBig}></button>
        <button className="right" onClick={slideRightBig}></button>
      </Big>
      <Options>
        <button className="left" onClick={slideLeft}></button>
        <div className="holder">
          <div style={{ transform: `translateX(-${slide * pace}%)` }}>
            {gallery.map((item, index) => {
              return (
                <span
                  key={index}
                  onClick={() => {
                    setActiveImageIndex(index);
                  }}
                  style={{ backgroundImage: `url(${getThumbnail(item.url)})` }}
                ></span>
              );
            })}
          </div>
        </div>
        <button className="right" onClick={slideRight}></button>
      </Options>
    </Container>
  );
}
