import React, { useContext, useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";

import HeaderModal from "./header";
import GalleryModal from "./gallery";
import ContentModal from "./content";
import SidebarModal from "./sidebar";

import api from "../../services/api";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Newsletter from "../../components/newsletter";

const GlobalStyle = createGlobalStyle`
  .ReactModal__Overlay {
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.6)!important;
  }

  .ReactModal__Content {
    left: 20px!important;
    right: 20px!important;
    border: 0!important;
  }

  .content-modal {
    padding-top: 45px;
    padding-bottom: 30px;
  }

  .modal-content.modal-out {
    width: 100%;
    padding: 50px 20px;
    margin: 0 auto;
  }
  .modal-background {
    background: #fff;
  }

  @media (min-width: 767px) {
    .ReactModal__Content {
      padding: 35px!important;
      left: 40px!important;
      right: 40px!important;
    }

    .content-modal {
      padding-bottom: 55px;
    }

    .modal-content.modal-out {
      max-width: 767px;
    }
  }

  @media (min-width: 1200px) {
    .ReactModal__Content {
      padding: 35px 100px!important;
      max-width: 1200px;
      margin: 0 auto;
    }

    .content-modal {
      display: flex;
      justify-content: space-between;
      padding-bottom: 85px;

      .content-side {
        width: 60%;
      }
      .sidebar-side {
        width: 30%;
      }
    }

    .modal-content.modal-out {
      max-width: 1200px;
      padding: 50px 15px;
    }
  }
`;

export default function Project({
  pageContext,
  modalProp,
  modalCloseCallBack,
}) {
  const getImages = (value) => {
    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  };
  const gallery = getImages(pageContext.gallery);
  const products = getImages(pageContext.products);
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    async function getProfileData() {
      const responseProfile = await api.get(
        `https://apimeuambiente.guararapes.com.br/user/${pageContext.user_id}/projects/`
      );
      pageContext.user = responseProfile.data[0].user;
      setProfileData(responseProfile.data[0].user);
    }
    getProfileData();
    api.get(
      `https://apimeuambiente.guararapes.com.br/project/${pageContext.id}/view/`
    );
  }, []);

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          {((!modalProp && typeof modalProp !== "undefined") ||
            (!modal && typeof modalProp === "undefined")) && <Header />}
          <div className="modal-background">
            <div
              className={!modal ? "modal-content modal-out" : "modal-content"}
            >
              <HeaderModal
                modal={modal}
                closeTo={closeTo}
                name={pageContext.user.company_name}
                image={
                  pageContext.user.company_avatar !== null
                    ? pageContext.user.company_avatar
                    : "/images/01.png"
                }
                projectId={pageContext.id}
                profileId={pageContext.user_id}
                likes={pageContext.likes}
                modalCloseCallBack={modalCloseCallBack}
              />
              {gallery !== null && <GalleryModal gallery={gallery} />}

              <div className="content-modal">
                <div className="content-side">
                  <ContentModal
                    name={pageContext.name}
                    city={pageContext.city}
                    is_3d={pageContext.is_3d}
                    state={pageContext.uf}
                    about={pageContext.about}
                    credit_maker={pageContext.credit_maker}
                    link_maker={pageContext.link_maker}
                    credit_photography={pageContext.credit_photography}
                    created_at={pageContext.created_at}
                    link_photography={pageContext.link_photography}
                  />
                </div>
                <div className="sidebar-side">
                  <SidebarModal data={pageContext} dataProducts={products} />
                </div>
              </div>

              <GlobalStyle />
            </div>
          </div>

          {((!modalProp && typeof modalProp !== "undefined") ||
            (!modal && typeof modalProp === "undefined")) && (
            <>
              <Newsletter />
              <Footer />
            </>
          )}
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
}
