import React, { useContext, useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { Link } from "gatsby";

import { AuthContext } from "../../context/auth";
import api from "../../services/api";

const GlobalStyle = createGlobalStyle`
  .header-modal {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;

    .author-perfil {
      display: flex;
      align-items: center;

      span {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;
        display: inline-block;
        background-size: cover;
        background-position: center;
      }

      .author-name {
        h4 {
          font: italic bold 14px 'Times new roman';
          color: #323232;
          margin-bottom: 14px;
        }

        a {
          text-transform: uppercase;
          font-size: 11px;
          color: #959595;

          display: flex;
          align-items: center;

          i {
            background: url("/images/arrow-right-bold.png") no-repeat center;
            background-size: contain;
            width: 17px;
            height: 12px;
            margin-right: 6px;
          }

          span{
            width: 80px;
            height: 18px;
          }
        }
      }
    }

    .header-actions {

      .btn-likes {
        cursor: pointer;
        margin-top: 16px;
        margin-left: auto;
        height: 36px;
        border: 2px solid #f1a857;
        border-radius: 3px;
        background: #ffffff;

        display: flex;
        align-items: center;

        transition: .2s all ease;

        &.disabled {
          cursor :none;
          position: relative;
          
          div {
            display: none;
          }

          &:hover {
            div {
              position: absolute;
              top: 50px;
              background: #f1a857;
              padding: 4px 12px;
              color: #fff;
              display: block;
            }
          }
        }

        span {
          font-size: 12px;
          color: #6a6a6a;
          min-width: 50px;
        }

        i {
          background: url('/images/heart.svg') no-repeat center;
          background-size: contain;
          width: 37px;
          height: 100%;
          background-size: 16px;
          border-right: 1px solid #f1a857;
        }

        &:hover,
        &.liked {
          background: #f1a857;

          span {
            color: #ffffff;
          }

          i {
            border-right: 1px solid #e0933d;
          }
        }
      }

      .close-modal {
        margin-left: auto;
        background: #f1a857;
        height: 34px;
        width: 53px;
        border-radius: 3px;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: .2s all ease;

        i {
          background: url("/images/streamline-icon-close-140-x-140.png") no-repeat center;
          background-size: contain;
          width: 17px;
          height: 17px;
        }

        &:hover {
          background: #e59134;
        }
      }
    }

    @media (min-width: 767px) {
      align-items: center;
      margin-bottom: 40px;

      .author-perfil {

        img {
          margin-right: 27px;
        }

        .author-name {
          h4 {
            font: italic bold 20px 'Times new roman';
            margin-bottom: 12px;
          }

          a {
            font-size: 12px;
          }
        }
      }
      .header-actions {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .btn-likes {
          margin-top: 0;
          height: 50px;

          span {
            font-size: 16px;
            min-width: 110px;
            font-weight: 600;
          }

          i {
            width: 70px;
            background-size: 24px;
          }
        }

        .close-modal {
          margin-left: 16px;
          height: 50px;
          width: 83px;

          i {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
`;

export default function HeaderModal({
  modal,
  closeTo,
  projectId,
  likes,
  name,
  image,
  profileId,
  modalCloseCallBack,
}) {
  const [numLikes, setNum] = useState(likes);
  const { getUser, isLoggedIn } = useContext(AuthContext);
  const [liked, setLiked] = useState(false);

  async function checkLike() {
    if (!getUser) return false;
    if (!getUser.id) setLiked(false);
    else {
      const response = await api.get(
        `https://apimeuambiente.guararapes.com.br/project/${projectId}/user/${getUser.id}/like/check/`
      );
      setLiked(response.data.status);
    }
  }

  useEffect(() => {
    checkLike();
  }, [getUser]);

  async function handleFavorite() {
    await api.post(
      `https://apimeuambiente.guararapes.com.br/project/${projectId}/user/${getUser.id}/create/`
    );
    setNum(numLikes + 1);
    setLiked(true);
  }

  async function handleRemoveFavorite() {
    await api.post(
      `https://apimeuambiente.guararapes.com.br/project/${projectId}/user/${getUser.id}/remove/`
    );
    setNum(numLikes - 1);
    setLiked(false);
  }

  return (
    <>
      <div className="header-modal">
        <div className="author-perfil">
          <span style={{ backgroundImage: `url(${image})` }}></span>
          <div className="author-name">
            <h4>{name}</h4>
            <Link to={`/perfil/${profileId}`}>
              <i></i>
              <span>ver perfil</span>
            </Link>
          </div>
        </div>

        <div className="header-actions">
          {modalCloseCallBack && (
            <span
              className="close-modal"
              style={{ cursor: "pointer" }}
              onClick={() => {
                modalCloseCallBack(false);
              }}
            >
              <i></i>
            </span>
          )}
          {modal && (
            <Link to={closeTo} className="close-modal">
              <i></i>
            </Link>
          )}
          {isLoggedIn ? (
            <>
              {liked ? (
                <button
                  className="btn-likes liked"
                  onClick={handleRemoveFavorite}
                >
                  <i></i>
                  <span>{numLikes}</span>
                </button>
              ) : (
                <button className="btn-likes" onClick={handleFavorite}>
                  <i></i>
                  <span>{numLikes}</span>
                </button>
              )}
            </>
          ) : (
            <button className="btn-likes disabled">
              <i></i>
              <span>{numLikes}</span>
              <div>Você precisa estar logado para dar like.</div>
            </button>
          )}
        </div>
      </div>

      <GlobalStyle />
    </>
  );
}
