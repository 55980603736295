import React from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt-br';

const Container = styled.div`
  h1 {
    font: italic bold 40px 'Times new roman';
    color: #000000;
    margin-bottom: 12px;
  }

  .location {
    font-size: 14px;
    color: #959595;
    display: block;
    margin-bottom: 30px;

    i {
      font-style: normal;
      text-transform: uppercase;
    }
  }

  .description {
    margin-bottom: 35px;

    p {
      font-size: 14px;
      line-height: 24px;
      color: #616161;
      letter-spacing: 0.6px;
      margin-bottom: 15px;
    }
  }

  .credits {
    margin-bottom: 25px;
    
    p {
      font-size: 14px;
      line-height: 25px;
      color: #616161;
      font-weight: 700;
      margin-bottom: 0;

      &.margin {
       margin: 0 0 25px; 
      }
    }
  }

  .date {
    font-size: 14px;
    color: #959595;
    display: block;
  }

  @media (min-width: 1200px) {
    .description {
      margin-bottom: 0;
    }
  }
`;

export default function ContentModal({ name, city, state, about, credit_maker, link_maker, link_photography, is_3d, credit_photography, created_at }) {
  return (
    <Container>
      <h1>{name}</h1>
      <span className="location">{city}, <i>{state}</i></span>
      <div className="description">
        <p>{about}</p>
      </div>
      <div className="credits">
        {
          !is_3d || is_3d === 0 ? '' : (
            <p class="margin">Imagens do projeto são ilustrações em 3d</p>
          )
        }
        {
          credit_maker ? (
            link_maker ? (
              <p>Marcenaria: <a href={ link_maker } target="_blank">{credit_maker}</a></p>
            ) : (
              <p>Marcenaria: {credit_maker}</p>
            )
          ) : 
          ''
        }
        {
          credit_photography ? (
            link_photography ? (
              <p>Fotografia: <a href={ link_photography } target="_blank">{credit_photography}</a></p>
            ) : (
              <p>Fotografia: {credit_photography}</p>
            )
          ) :
          ''
        }
      </div>
      <span className="date">
        Postado em {' '}
        <Moment format={ 'LL' } locale="pt-br">
          {created_at}
        </Moment>
      </span>
    </Container>
  )
}