import React from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from 'gatsby';
import slugify from 'slugify';

const Container = styled.div`
  background: #ebebeb;
  border-radius: 3px;
  margin-top: 45px;

  .share {
    padding: 25px 24px 20px;

    ul {
      display: flex;

      li {
        margin-right: 6px;
        max-width: 59px;
        width: 100%;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 53px;
          border-radius: 3px;
          background: #ffffff!important;

          transition: .2s all ease;

          i.icon-fb {
            background: url("/images/icon_fb.svg") no-repeat center;
            background-size: contain;
            width: 12px;
            height: 22px;
          }
          i.icon-pinterest {
            background: url("/images/icon_pinterest.svg") no-repeat center;
            background-size: contain;
            width: 16px;
            height: 21px;
          }
          i.icon-twitter {
            background: url("/images/icon_twitter.svg") no-repeat center;
            background-size: contain;
            width: 25px;
            height: 20px;
          }
          i.icon-wp {
            background: url("/images/icon_wp.svg") no-repeat center;
            background-size: contain;
            width: 22px;
            height: 22px;
          }

          &:hover {
            opacity: 0.6;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .view-more {
    padding: 20px 24px 26px;
    display: flex;
    flex-wrap: wrap;
    

    h3 {
      width: 100%;
    }

    a {
      background: #ffffff;
      border-radius: 3px;
      color: #959595;
      font-size: 14px;
      line-height: 38px;
      padding: 0 20px;
      text-transform: uppercase;
      margin: 0 10px 10px 0;
    }
  }

  .products-used {
    padding: 20px 24px 26px;

    ul {
      display: flex;
      flex-direction: column;

      li {
        width: 100%;
        margin-bottom: 15px;

        a {
          display: flex;
          align-items: center;
          background: #ffffff;
          border-radius: 3px;

          img {
            width: 100px;
            height: 100px;
            border-radius: 3px;
            margin-right: 10px;
          }

          h5 {
            font: italic bold 20px 'Times new roman';
            color: #959595;
            margin-bottom: 0;
          }

          span {
            text-transform: uppercase;
            font-size: 12px;
            color: #959595;
          }

          p {
            margin-top: 20px;
            font-size: 12px;
            color: #959595;
            margin-bottom: 0;

            display: flex;
            align-items: center;

            i {
              background: url("/images/arrow-right-bold.png") no-repeat center;
              background-size: contain;
              width: 14px;
              height: 10px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }

  h3 {
    font: italic bold 18px 'Times new roman';
    color: #000000;
    margin-bottom: 18px;
  }

  @media (min-width: 767px) {
    .products-used {
      ul {
        overflow-x: auto;
        flex-direction: row;

        li {
          min-width: 256px;
          margin-right: 20px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    margin-top: 0;

    .products-used {
      border-top: 1px solid #dedede;

      ul {
        flex-direction: column;

        li {
          width: 100%;
          min-width: 100%;
          margin-right: 0;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export default function SidebarModal({ data, dataProducts }) {
  return (
    <Container>
      <div className="share">
        <h3>Compartilhe:</h3>
        <ul>
          <li>
            <FacebookShareButton url={`https://meuambiente.guararapes.com.br/ambiente/${data.id}`}>
              <i className="icon-fb"></i>
            </FacebookShareButton>
          </li>
          <li>
            <PinterestShareButton url={`https://meuambiente.guararapes.com.br/ambiente/${data.id}`}>
              <i className="icon-pinterest"></i>
            </PinterestShareButton>
          </li>
          <li>
            <TwitterShareButton url={`https://meuambiente.guararapes.com.br/ambiente/${data.id}`}>
              <i className="icon-twitter"></i>
            </TwitterShareButton>
          </li>
          <li>
            <WhatsappShareButton url={`https://meuambiente.guararapes.com.br/ambiente/${data.id}`}>
              <i className="icon-wp"></i>
            </WhatsappShareButton>
          </li>
        </ul>
      </div>
      {(dataProducts && dataProducts.length > 0) && (
        <div className="products-used">
          <h3>Produtos Utilizados:</h3>
          <ul>
            {dataProducts.map((product, index) => (
              <li key={index}>
                {
                  product.node ? (
                    <>
                      <a href={`https://guararapes.com.br/produto/${slugify(product.node.data.nome[0].text).toLowerCase()}`} target="_blank" rel="noreferrer">
                        <img src={product.node.data.imagem_principal.url ? product.node.data.imagem_principal.url : '/images/product.png'} alt={product.node.data.nome[0].text}/>
                        <div>
                          <h5>{product.node.data.nome[0].text}</h5>
                          {product.node.data.categoria_produto.uid && (
                            <span>Linha {product.node.data.categoria_produto.uid && product.node.data.categoria_produto.uid.split('-').join(" ")}</span>
                          )}
                          <p>
                            <i></i>
                            Ver produto
                          </p>
                        </div>
                      </a>
                    </>
                  ) : (
                    <>
                      <a href={`https://guararapes.com.br/produto/${slugify(product.data.nome[0].text).toLowerCase()}`} target="_blank" rel="noreferrer">
                        <img src={product.data.imagem_principal.url ? product.data.imagem_principal.url : '/images/product.png'} alt={product.data.nome[0].text}/>
                        <div>
                          <h5>{product.data.nome[0].text}</h5>
                          {product.data.categoria_produto.uid && (
                            <span>Linha {product.data.categoria_produto.uid && product.data.categoria_produto.uid.split('-').join(" ")}</span>
                          )}
                          <p>
                            <i></i>
                            Ver produto
                          </p>
                        </div>
                      </a>
                    </>
                  )
                }
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="view-more">
        <h3>Veja mais em:</h3>
        {dataProducts && dataProducts.map((product, index) => 
          product.node ? (
            <Link to={`/ambientes/?mdf=${product.node.data.nome[0].text.toLowerCase().replace('\ \g', '-')}`} key={ index }>
              {product.node.data.nome[0].text}
            </Link>
          ) : (
            <Link to={`/ambientes/?mdf=${product.data.nome[0].text.toLowerCase().replace('\ \g', '-')}`} key={ index }>
              {product.data.nome[0].text}
            </Link>
          )
        )}
      </div>
    </Container>
  )
}